import React from 'react'
import '../Layout/LayoutCSS/FooterCSS.css'
import '../Pages/PagesCSS/ColorSchemeCSS.css'

function Footer() {
  return (
    <div className='footer-con'>Created with React!</div>
  )
}

export default Footer