import React, { useEffect, useState } from 'react'
import './PagesCSS/HomeCSS.css'
import headshot from '../Images/GordonPalagi.JPEG'
import SocialsData from '../Data/SocialsData';
import './PagesCSS/ColorSchemeCSS.css'


function Home() {

  const [bio, setBio] = useState();



    fetch("http://api.github.com/users/gordonpalagi")
    .then((res) => res.json())
    .then(
      (result) => {
        setBio(result.bio);
    },
    (error) => {
        console.log(error);
    }
    )


const heading = 
  <div className='heading-con'>
    <div className='heading'>
        <h1>Gordon Palagi</h1>
        <h4>Full Stack Web Developer</h4>
        <p>{bio}</p>
        <div className='icon-con'>
          {SocialsData.map((item, index) => {
            return (
              <div key={index}>
                <a href={item.link}>
                  <span style={{color: item.color}} className='icons'>{item.icon}</span>
                </a>
              </div>
              )
            })}
        </div>
    </div>
    <div className='headshot-con'>
      <img className='headshot' src={headshot} alt='Gordon Palagi'/>
    </div>
  </div>


return (
    <div className='full-home-con'>
        {heading}
    </div>
)


}

export default Home