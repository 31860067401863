import './App.css';
import Home from './Pages/Home'
import Experience from './Pages/LanguageExperience';
import Layout from './Layout/Layout';
import Projects from './Pages/Projects';
import WorkExperience from './Pages/WorkExperience';

function App() {



  return (
    <Layout>
      <div className='App'>
        <Home/>
        <Experience/>
        <WorkExperience/>
        <Projects/>
      </div>
    </Layout>
  )
}

export default App;
