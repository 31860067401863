import * as GrIcons from 'react-icons/gr'
import * as BsIcons from 'react-icons/bs';
import * as AiIcons from 'react-icons/ai';


export default 

[
    {
        icon: <GrIcons.GrGithub/>,
        link: "https://github.com/GordonPalagi",
        color: "#D3FBD8",
    },

    {
        icon: <BsIcons.BsLinkedin/>,
        link: "https://www.linkedin.com/in/gordon-palagi-980b711b4/",
        color: "#D3FBD8",
    },

    {
        icon: <AiIcons.AiOutlineMail/>,
        link: "",
        color: "#D3FBD8",
    }
]