import React from 'react'
import './PagesCSS/WorkExperienceCSS.css'
import './PagesCSS/ColorSchemeCSS.css'


function WorkExperience() {

    const lineElement = 
        <div className='lineElement-con'>
            <div className='dot one'></div>
            <hr className='line'/>
            <div className='dot two'></div>
        </div>


  return (
    <div className='work-con'>
        {lineElement}
        <div className='resume-con'>
            <h3>Merit America</h3>
            <p>31-week intensive program focused on Full Stack Web Application Development, including hands-on coursework in Java Development, Client-Server Programming (SQL + Spring) and Frontend Development (Classic Web + React). </p>
        </div>
    </div>
  )
}

export default WorkExperience