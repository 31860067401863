import React from 'react'
import {useState} from 'react'
import ProjectData from '../Data/ProjectData'
import './PagesCSS/ProjectsCSS.css'
import './PagesCSS/ColorSchemeCSS.css'


function Projects() {

    const projects = ProjectData.map((item, index) => {
        return (
            <div className='project-inner-con' key={index}>
                <a  href={item.link}>
                    <span>{item.title}</span>
                    <p>{item.tech}</p>
                </a>
            </div>
        )
    })


  return (
    <div className='project-con'>
        <div className='project-headline'>Projects</div>
        <hr className='project-hr'/>
        <div className='project-grid'>
            {projects}
        </div>
    </div>
  )
}

export default Projects