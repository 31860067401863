

export default

[
    {
        title: "Crafty",
        link: "https://merit-america.git.techelevator.com/Qoios/final-capstone",
        tech: "Do you run a Brewery? Or just a fan of Breweries? I created Crafty! A craft brewery managemnet app, so a brewery can manage it's news and events, complete with a beer jounral so your customers can rate your home crafted beers. Made with React, Java, and CSS!"
    },

    {
        title: "Tenmo",
        link: "https://github.com/GordonPalagi/TEnmo",
        tech: "Tenmo is a Venmo clone made with Java and Spring Boot"
    },

    {
        title: "VendoMatic-800",
        link: "https://github.com/GordonPalagi/VendoMatic-800/tree/main/capstone",
        tech: "A fully functional console line vending machine made with React and Spring Booot"
    },

    {
        title: "Popsicle Blog",
        link: "https://github.com/GordonPalagi/Popsicles",
        tech: "Read about the interesting history of the popsicle! Made with HTML and CSS"
    }
]