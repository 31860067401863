import './PagesCSS/LanguageExperience.css'
import Data from '../Data/LanguageData'
import './PagesCSS/ColorSchemeCSS.css'


function LanguageExperience() {
  
    const languageExp = Data.map((item, index) => {
        return (
            <div key={index} className='exp-con'>
                <span style={{color: item.color}} className='lang-icon'>{item.icon}</span>
                <span>{item.description}</span>
            </div>
        )
    })

    return (
        <div className='experience-con'>
            <div className='language-con'>
                <span>Langauge Experience</span>
                <hr/>
                {languageExp}
            </div>
        </div>
    )



}

export default LanguageExperience